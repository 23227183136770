<template>
  <div class="contact-page">
    <!-- Header with 'Back to Previous Page' -->
    <Header
      :showBackButton="true"
      backText="Back to Previous Page"
      @back="goBack"
    />

    <!-- Identical container layout as HomePage & ArticlePage -->
    <main
      class="container"
      ref="container"
      role="main"
    >
      <h1 class="page-title">Contact</h1>

      <p class="subtitle">
        We’d love to hear from you!
      </p>
      <p class="email">
        resuvonia@gmail.com
      </p>
    </main>
  </div>
</template>

<script>
import Header from "./Header.vue";

export default {
  name: "ContactPage",
  components: { Header },
  mounted() {
    document.title = "Contact";
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.contact-page {
  /* Same approach: entire page from top to bottom, 
     with the header fixed above. */
  display: flex;
  flex-direction: column;
  height: 100vh;

  /* Subtle gradient background to match your “best style” */
  background: linear-gradient(135deg, #f5f7fa, #d7e1ec);
}

/* Container: 
   - margin-top: 80px => below the header
   - min-height => fill rest of screen
   - overflow-y => scrolling for entire page
   - width: 100% => scrollbar on far right
*/
.container {
  margin-top: 80px;
  min-height: calc(100vh - 80px);
  overflow-y: auto;
  scroll-behavior: smooth;
  box-sizing: border-box;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-bottom: 120px;
}

/* Headings & text styling, consistent with Home/Article pages */
.page-title {
  font-family: "Georgia", serif;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.subtitle {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.1rem;
  color: #444;
  max-width: 500px;
}

.email {
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #333;
}
</style>
