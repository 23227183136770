// src/data/simplerBooks.js
const simplerBooks = [
  {
    "id": 1,
    "title": "813 by Maurice Leblanc (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/S813.txt"
    }
  },
  {
    "id": 2,
    "title": "A Christmas Carol by Charles Dickens (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sa-christmas-carol.txt"
    }
  },
  {
    "id": 3,
    "title": "A Tale of Two Cities by Charles Dickens (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sa-tale-of-two-cities.txt"
    }
  },
  {
    "id": 4,
    "title": "A Connecticut Yankee in King Arthur's Court by Mark Twain (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sa-connecticut-yankee-in-king-arthurs-court.txt"
    }
  },
  {
    "id": 5,
    "title": "A Midsummer Night's Dream by William Shakespeare (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sa-midsummer-nights-dream.txt"
    }
  },
  {
    "id": 6,
    "title": "Alice's Adventures in Wonderland by Lewis Carroll (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Salice-in-wonderland.txt"
    }
  },
  {
    "id": 7,
    "title": "Anna Karenina by Leo Tolstoy (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sanna-karenina.txt"
    }
  },
  {
    "id": 8,
    "title": "Adventures of Huckleberry Finn by Mark Twain (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sadventures-of-huckleberry.txt"
    }
  },
  {
    "id": 9,
    "title": "Bleak House by Charles Dickens (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sbleak-house.txt"
    }
  },
  {
    "id": 10,
    "title": "A Doll's House by Henrik Ibsen (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sa-doll-s-house.txt"
    }
  },
  {
    "id": 11,
    "title": "A Room with a View by E. M. Forster (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sa-room-with-a-view.txt"
    }
  },
  {
    "id": 12,
    "title": "A Woman of No Importance by Oscar Wilde (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sa-woman-of-no-importance.txt"
    }
  },
  {
    "id": 13,
    "title": "Anne of Green Gables by L. M. Montgomery (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sanne-of-green-gables.txt"
    }
  },
  {
    "id": 14,
    "title": "Anne of the Island by L. M. Montgomery (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sanne-of-the-island.txt"
    }
  },
  {
    "id": 15,
    "title": "A Hero of Our Time by Mikhail Lermontov (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sa-hero-of-our-time.txt"
    }
  },
  {
    "id": 16,
    "title": "Adam Bede by George Eliot (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sadam-bede.txt"
    }
  },
  {
    "id": 17,
    "title": "A Tramp Abroad by Mark Twain (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sa-tramp-abroad.txt"
    }
  },
  {
    "id": 18,
    "title": "Buddenbrooks Verfall Einer Familie by Thomas Mann (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sbuddenbrooks-verfall-einer-familie.txt"
    }
  },
  {
    "id": 19,
    "title": "Barchester Towers by Anthony Trollope (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sbarchester-towers.txt"
    }
  },
  {
    "id": 20,
    "title": "Beowulf by Anonymous (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sbeowulf-an-anglo-saxon-epic-poem.txt"
    }
  },
  {
    "id": 21,
    "title": "Clarissa Harlowe; or, The History of a Young Lady, Volume 1 by Samuel Richardson (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sclarissa-harlowe-or-the-history-of-a-young-lady-volume-1.txt"
    }
  },
  {
    "id": 22,
    "title": "Cranford by Elizabeth Gaskell (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Scranford.txt"
    }
  },
  {
    "id": 23,
    "title": "Crime and Punishment by Fyodor Dostoevsky (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Scrime-and-punishment.txt"
    }
  },
  {
    "id": 24,
    "title": "David Copperfield by Charles Dickens (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sdavid-copperfield.txt"
    }
  },
  {
    "id": 25,
    "title": "De Decamerone van Boccaccio by Giovanni Boccaccio (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sde-decamerone-van-boccaccio.txt"
    }
  },
  {
    "id": 26,
    "title": "Dead Souls by Nikolai Gogol (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sdead-souls.txt"
    }
  },
  {
    "id": 27,
    "title": "Demian by Hermann Hesse (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sdemian.txt"
    }
  },
  {
    "id": 28,
    "title": "Don Quixote by Miguel de Cervantes Saavedra (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sdon-quixote.txt"
    }
  },
  {
    "id": 29,
    "title": "Emma by Jane Austen (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Semma.txt"
    }
  },
  {
    "id": 30,
    "title": "Eugene Oneguine [Onegin] by Alexander Pushkin (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Seugene-oneguine-[onegin].txt"
    }
  },
  {
    "id": 31,
    "title": "Evelina, Or the History of a Young Lady's Entrance into the World by Fanny Burney (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sevelina-or-the-history-of-a-young-ladys-entrance-into-the-world.txt"
    }
  },
  {
    "id": 32,
    "title": "Faust [Part 1] by Johann Wolfgang von Goethe (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sfaust-[part-1].txt"
    }
  },
  {
    "id": 33,
    "title": "Faust Der Tragödie Erster Teil by Johann Wolfgang von Goethe (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sfaust-der-tragödie-erster-teil.txt"
    }
  },
  {
    "id": 34,
    "title": "Faust Der Tragödie Zweiter Teil by Johann Wolfgang von Goethe (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sfaust-der-tragödie-zweiter-teil.txt"
    }
  },
  {
    "id": 35,
    "title": "Far From the Madding Crowd by Thomas Hardy (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sfar-from-the-madding-crowd.txt"
    }
  },
  {
    "id": 36,
    "title": "Father Goriot by Honoré de Balzac (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sfather-goriot.txt"
    }
  },
  {
    "id": 37,
    "title": "Great Expectations by Charles Dickens (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sgreat-expectations.txt"
    }
  },
  {
    "id": 38,
    "title": "Gulliver's Travels by Jonathan Swift (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sgullivers-travels.txt"
    }
  },
  {
    "id": 39,
    "title": "Heart of Darkness by Joseph Conrad (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sheart-of-darkness.txt"
    }
  },
  {
    "id": 40,
    "title": "Hunger by Knut Hamsun (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Shunger.txt"
    }
  },
  {
    "id": 41,
    "title": "Jude the Obscure by Thomas Hardy (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sjude-the-obscure.txt"
    }
  },
  // Notice no id: 42 in this snippet
  {
    "id": 43,
    "title": "King Lear by William Shakespeare (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sking-lear.txt"
    }
  },
  {
    "id": 44,
    "title": "Lady Audley's Secret by Mary Elizabeth Braddon (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Slady-audleys-secret.txt"
    }
  },
  {
    "id": 45,
    "title": "Le Comte de Monte Cristo Tome I by Alexandre Dumas (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sle-comte-de-monte-cristo-tome-I.txt"
    }
  },
  {
    "id": 46,
    "title": "Les Misérables by Victor Hugo (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sles-miserables.txt"
    }
  },
  {
    "id": 47,
    "title": "Lord Jim by Joseph Conrad (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Slord-jim.txt"
    }
  },
  {
    "id": 48,
    "title": "Mansfield Park by Jane Austen (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Smansfield-park.txt"
    }
  },
  {
    "id": 49,
    "title": "Madame Bovary by Gustave Flaubert (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Smadame-bovary.txt"
    }
  },
  {
    "id": 50,
    "title": "Moby Dick by Herman Melville (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Smoby-dick.txt"
    }
  },
  {
    "id": 51,
    "title": "Middlemarch by George Eliot (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Smiddlemarch.txt"
    }
  },
  {
    "id": 52,
    "title": "Northanger Abbey by Jane Austen (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Snorthanger-abbey.txt"
    }
  },
  {
    "id": 53,
    "title": "Notes from the Underground by Fyodor Dostoevsky (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Snotes-from-the-underground.txt"
    }
  },
  {
    "id": 54,
    "title": "Notre-Dame de Paris Tome I by Victor Hugo (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Snotre-dame-de-paris-tome-I.txt"
    }
  },
  {
    "id": 55,
    "title": "Oedipus King of Thebes by Sophocles (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Soedipus-king-of-thebes.txt"
    }
  },
  {
    "id": 56,
    "title": "Of Human Bondage by W. Somerset Maugham (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sof-human-bondage.txt"
    }
  },
  {
    "id": 57,
    "title": "Oliver Twist by Charles Dickens (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Soliver-twist.txt"
    }
  },
  {
    "id": 58,
    "title": "Othello, the Moor of Venice by William Shakespeare (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sothello-the-moor-of-venice.txt"
    }
  },
  {
    "id": 59,
    "title": "Paradise Lost by John Milton (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sparadise-lost.txt"
    }
  },
  {
    "id": 60,
    "title": "Persuasion by Jane Austen (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Spersuasion.txt"
    }
  },
  {
    "id": 61,
    "title": "Peter Pan by J. M. Barrie (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Speter-pan.txt"
    }
  },
  {
    "id": 62,
    "title": "Pride and Prejudice by Jane Austen (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Spride-and-prejudice.txt"
    }
  },
  {
    "id": 63,
    "title": "Romeo and Juliet by William Shakespeare (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sromeo-juliet.txt"
    }
  },
  {
    "id": 64,
    "title": "She by H. Rider Haggard (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sshe.txt"
    }
  },
  {
    "id": 65,
    "title": "Siddhartha by Hermann Hesse (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Ssiddhartha.txt"
    }
  },
  {
    "id": 66,
    "title": "Sense and Sensibility by Jane Austen (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Ssense-and-sensibility.txt"
    }
  },
  {
    "id": 67,
    "title": "Tess of the d'Urbervilles by Thomas Hardy (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Stess-of-the-d-urbervilles.txt"
    }
  },
  {
    "id": 68,
    "title": "The Adventures of Tom Sawyer by Mark Twain (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-adventures-of-tom-sawyer.txt"
    }
  },
  {
    "id": 69,
    "title": "The Adventures of Sherlock Holmes by Arthur Conan Doyle (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-adventures-of-sherlock.txt"
    }
  },
  {
    "id": 70,
    "title": "The Aeneid by Virgil (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-aeneid.txt"
    }
  },
  {
    "id": 71,
    "title": "The Arabian Nights: Their Best-Known Tales by Anonymous (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-arabian-nights-their-best-known-tales.txt"
    }
  },
  {
    "id": 72,
    "title": "The Brothers Karamazov by Fyodor Dostoevsky (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-brothers-karamazov.txt"
    }
  },
  {
    "id": 73,
    "title": "The Call of the Wild by Jack London (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-call-of-the-wild.txt"
    }
  },
  {
    "id": 74,
    "title": "The Canterbury Tales and Other Poems by Geoffrey Chaucer (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-canterbury-tales-and-other-poems.txt"
    }
  },
  {
    "id": 75,
    "title": "The Count of Monte Cristo by Alexandre Dumas (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-count-of-monte-cristo.txt"
    }
  },
  {
    "id": 76,
    "title": "The Divine Comedy by Dante Alighieri (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-divine-comedy.txt"
    }
  },
  {
    "id": 77,
    "title": "The Great Gatsby by F. Scott Fitzgerald (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-great-gatsby.txt"
    }
  },
  {
    "id": 78,
    "title": "The Iliad by Homer (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-iliad.txt"
    }
  },
  {
    "id": 79,
    "title": "The Odyssey by Homer (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-odyssey.txt"
    }
  },
  {
    "id": 80,
    "title": "The Hound of the Baskervilles by Arthur Conan Doyle (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-hound-of-the-baskervilles.txt"
    }
  },
  {
    "id": 81,
    "title": "The Idiot by Fyodor Dostoevsky (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-idiot.txt"
    }
  },
  {
    "id": 82,
    "title": "The Importance of Being Earnest by Oscar Wilde (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-importance-of-being-earnest.txt"
    }
  },
  {
    "id": 83,
    "title": "The Jungle Book by Rudyard Kipling (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-jungle-book.txt"
    }
  },
  {
    "id": 84,
    "title": "The Legend of Sleepy Hollow by Washington Irving (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-legend-of-sleepy-hollow.txt"
    }
  },
  {
    "id": 85,
    "title": "The Prince and the Pauper by Mark Twain (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-prince-and-the-pauper.txt"
    }
  },
  {
    "id": 86,
    "title": "The House of the Seven Gables by Nathaniel Hawthorne (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-house-of-the-seven-gables.txt"
    }
  },
  {
    "id": 87,
    "title": "The Metamorphoses of Ovid by Ovid (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-metamorphoses.txt"
    }
  },
  {
    "id": 88,
    "title": "The Scarlet Letter by Nathaniel Hawthorne (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-scarlet-letter.txt"
    }
  },
  {
    "id": 89,
    "title": "The Three Musketeers by Alexandre Dumas (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-three-musketeers.txt"
    }
  },
  {
    "id": 90,
    "title": "The Time Machine by H. G. Wells (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-time-machine.txt"
    }
  },
  {
    "id": 91,
    "title": "The Scarlet Pimpernel by Baroness Orczy (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-scarlet-pimpernel.txt"
    }
  },
  {
    "id": 92,
    "title": "The Scarlet Plague by Jack London (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-scarlet-plague.txt"
    }
  },
  {
    "id": 93,
    "title": "The Strange Case of Dr Jekyll and Mr Hyde by Robert Louis Stevenson (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-trange-case-of-dr-jekyll-and-mr-hyde.txt"
    }
  },
  {
    "id": 94,
    "title": "The Turn of the Screw by Henry James (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-turn-of-the-screw.txt"
    }
  },
  {
    "id": 95,
    "title": "The Trial by Franz Kafka (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-trial.txt"
    }
  },
  {
    "id": 96,
    "title": "The War of the Worlds by H. G. Wells (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-war-of-the-worlds.txt"
    }
  },
  {
    "id": 97,
    "title": "The Wind in the Willows by Kenneth Grahame (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-wind-in-the-willows.txt"
    }
  },
  {
    "id": 98,
    "title": "The Wonderful Wizard of Oz by L. Frank Baum (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-wonderful-wizard-of-oz.txt"
    }
  },
  {
    "id": 99,
    "title": "The Republic by Plato (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-republic.txt"
    }
  },
  {
    "id": 100,
    "title": "Treasure Island by Robert Louis Stevenson (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Streasure-island.txt"
    }
  },
  {
    "id": 101,
    "title": "Twelfth Night; Or, What You Will by William Shakespeare (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Stwelfth-night-or-what-you-will.txt"
    }
  },
  {
    "id": 102,
    "title": "Twenty Thousand Leagues Under the Sea by Jules Verne (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Stwenty-thousand-leagues-under-the-sea.txt"
    }
  },
  {
    "id": 103,
    "title": "War and Peace by Leo Tolstoy (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Swar-and-peace.txt"
    }
  },
  {
    "id": 104,
    "title": "Wuthering Heights by Emily Brontë (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Swuthering-heights.txt"
    }
  },
  {
    "id": 105,
    "title": "Jane Eyre by Charlotte Brontë (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sjane-eyre.txt"
    }
  },
  {
    "id": 106,
    "title": "Ulysses by James Joyce (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sulysses.txt"
    }
  },
  {
    "id": 107,
    "title": "Ivanhoe by Sir Walter Scott (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sivanhoe.txt"
    }
  },
  {
    "id": 108,
    "title": "Twice-Told Tales by Nathaniel Hawthorne (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Stwice-told-tales.txt"
    }
  },
  {
    "id": 109,
    "title": "A Journey Into the Interior of the Earth by Jules Verne (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sa-journey-into-the-interior-of-the-earth.txt"
    }
  },
  {
    "id": 110,
    "title": "A Princess of Mars by Edgar Rice Burroughs (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sa-princess-of-mars.txt"
    }
  },
  {
    "id": 112,
    "title": "Beyond Lies the Wub by Philip K. Dick (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sbeyond-lies-the-wub.txt"
    }
  },
  {
    "id": 113,
    "title": "Around the World in Eighty Days by Jules Verne (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Saround-the-world-in-eighty-days.txt"
    }
  },
  {
    "id": 114,
    "title": "After London or Wild England by Richard Jefferies (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Safter-london-or-wild-england.txt"
    }
  },
  {
    "id": 115,
    "title": "A Voyage to Arcturus by David Lindsay (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sa-voyage-to-arcturus.txt"
    }
  },
  {
    "id": 117,
    "title": "Amphitryo by Plautus (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Samphitryo.txt"
    }
  },
  {
    "id": 118,
    "title": "Childe Harold's Pilgrimage by Lord Byron (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Schilde-harold-s-pilgrimage.txt"
    }
  },
  {
    "id": 119,
    "title": "A Pail of Air by Fritz Leiber (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sa-pail-of-air.txt"
    }
  },
  {
    "id": 120,
    "title": "Etidorhpa, or The End of Earth by John Uri Lloyd (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Setidorhpa-or-the-end-of-earth.txt"
    }
  },
  {
    "id": 121,
    "title": "Five Children and It by E. Nesbit (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sfive-children-and-it.txt"
    }
  },
  {
    "id": 122,
    "title": "Herland by Charlotte Perkins Gilman (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sherland-by-charlotte.txt"
    }
  },
  {
    "id": 123,
    "title": "Frankenstein by Mary Wollstonecraft Shelley (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sfrankenstein.txt"
    }
  },

  // Newer entry with matching ID to the second list:
  {
    "id": 392,
    "title": "The Communist Manifesto (Simpler Version)",
    "price": 0.3,
    "s3Keys": {
      "txt": "paid/Sthe-communist-manifesto.txt"
    }
  }
];
export default simplerBooks;
