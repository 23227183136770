<template>
  <div class="donate-page">
    <!-- Header with back button -->
    <Header
      :showBackButton="true"
      backText="Back to Previous Page"
      @back="goBack"
    />

    <!-- Same container approach -->
    <main
      class="container"
      ref="container"
      role="main"
    >
      <h1 class="page-title">Donate If You Want</h1>
      <p class="subtitle">
        Your support helps us continue providing valuable resources.
        Any amount is greatly appreciated!
      </p>

      <div class="payment-options">
        <button
          @click="donateWithStripe"
          class="stripe-button"
          aria-label="Donate with Stripe"
        >
          Donate with Stripe
        </button>
      </div>

      <transition name="fade">
        <p
          v-if="errorMessage"
          class="error-message"
        >
          {{ errorMessage }}
        </p>
      </transition>
    </main>
  </div>
</template>

<script>
import Header from "./Header.vue";

export default {
  name: "DonatePage",
  components: { Header },
  data() {
    return {
      errorMessage: "",
    };
  },
  mounted() {
    document.title = "Donate";
  },
  methods: {
    donateWithStripe() {
      try {
        // Link to your donation page
        window.location.href = "https://donate.stripe.com/14k5mt0zk7DXf1m9AA";
      } catch (error) {
        this.errorMessage =
          "There was an error with the donation process. Please try again.";
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.donate-page {
  display: flex;
  flex-direction: column;
  height: 100vh;

  /* same subtle gradient background */
  background: linear-gradient(135deg, #f5f7fa, #d7e1ec);
}

/* Container: same as HomePage, so entire page scrolls from below header */
.container {
  margin-top: 80px;
  min-height: calc(100vh - 80px);
  overflow-y: auto;
  scroll-behavior: smooth;
  box-sizing: border-box;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-bottom: 120px;
}

.page-title {
  font-family: "Georgia", serif;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.subtitle {
  text-align: center;
  margin-bottom: 30px;
  font-size: 1.1rem;
  color: #444;
  max-width: 500px;
}

.payment-options {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.stripe-button {
  background: linear-gradient(45deg, #7b4397, #dc2430);
  color: #ffffff;
  border: none;
  border-radius: 6px;
  padding: 14px 20px;
  cursor: pointer;
  width: 100%;
  max-width: 300px;
  font-size: 16px;
  font-weight: 500;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  outline: none;
}

.stripe-button:hover,
.stripe-button:focus {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  background: linear-gradient(45deg, #ac4dac, #ff4747);
}

.error-message {
  color: #d9534f;
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  margin-top: 15px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
