import { createRouter, createWebHistory } from "vue-router";

import BooksPage from "@/components/BooksPage.vue";
import ContactPage from "@/components/ContactPage.vue";
import DonatePage from "@/components/DonatePage.vue";
import ChooseActionPage from "@/components/ChooseActionPage.vue";
import BookDetails from "@/components/BookDetails.vue";
import BookPieceByPiece from "@/components/BookPieceByPiece.vue";
import SimplerBookPurchase from "@/components/SimplerBookPurchase.vue";
import SimplerChooseActionPage from "@/components/SimplerChooseActionPage.vue";
import SimplerBookDetails from "@/components/SimplerBookDetails.vue";
import SimplerBookPieceByPiece from "@/components/SimplerBookPieceByPiece.vue";

const routes = [
  {
    path: "/",
    name: "books",
    component: BooksPage,
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactPage,
  },
  {
    path: "/donate",
    name: "donate",
    component: DonatePage,
  },
  {
    path: "/choose-action/:id",
    name: "chooseAction",
    component: ChooseActionPage,
    props: (route) => ({
      id: route.params.id,
      topicType: route.params.topicType || "book",
      topicId: route.params.topicId,
      topicName: route.params.topicName,
    }),
  },
  {
    path: "/book-details/:id",
    name: "bookDetails",
    component: BookDetails,
    props: true,
  },
  {
    path: "/book-piece-by-piece/:id",
    name: "bookPieceByPiece",
    component: BookPieceByPiece,
    props: true,
  },
  {
    path: "/simpler-book-purchase/:id",
    name: "simplerBookPurchase",
    component: SimplerBookPurchase,
    props: true,
  },
  {
    path: "/simpler-choose-action/:id",
    name: "simplerChooseAction",
    component: SimplerChooseActionPage,
    props: (route) => ({
      id: route.params.id,
      topicType: route.params.topicType || "book",
      topicId: route.params.topicId,
      topicName: route.params.topicName,
    }),
  },
  {
    path: "/simpler-book-details/:id",
    name: "simplerBookDetails",
    component: SimplerBookDetails,
    props: true,
  },
  {
    path: "/simpler-book-piece-by-piece/:id",
    name: "simplerBookPieceByPiece",
    component: SimplerBookPieceByPiece,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
