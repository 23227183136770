<!-- SimplerBookPieceByPiece.vue -->
<template>
  <div class="simpler-book-piece-by-piece-page">
    <Header
      :showBackButton="true"
      backText="Back to Choose Action"
      @back="goBackToChooseAction"
    />

    <main class="container" ref="container">
      <section v-if="!isLoading && !errorMessage" class="content">
        <h1 class="book-title">{{ book.title }}</h1>
        <div class="paragraph-container">
          <p class="paragraph-text">{{ currentParagraph }}</p>
        </div>
        <div class="instructions">
          <p>
            <strong>Desktop:</strong> Use the <strong>Space</strong> or
            <strong>Arrow Keys</strong> to navigate.
          </p>
          <p>
            <strong>Mobile:</strong> Use the navigation buttons or swipe
            left/right.
          </p>
        </div>
        <div class="navigation">
          <button
            v-if="paragraphs.length > 1"
            @click.prevent.stop="jumpToBeginning"
            class="nav-button beginning-button"
            aria-label="Jump to Beginning"
            :disabled="currentIndex === 0"
          >
            ⟸ Beginning
          </button>
          <button
            v-if="paragraphs.length > 1"
            @click.prevent.stop="previousParagraph"
            class="nav-button prev-button"
            aria-label="Previous Paragraph"
            :disabled="currentIndex === 0"
          >
            ← Previous
          </button>
          <button
            v-if="paragraphs.length > 1 && currentIndex < paragraphs.length - 1"
            @click.prevent.stop="nextParagraph"
            class="nav-button next-button"
            aria-label="Next Paragraph"
          >
            Next →
          </button>
          <button
            v-if="paragraphs.length > 1"
            @click.prevent.stop="jumpToEnd"
            class="nav-button end-button"
            aria-label="Jump to End"
            :disabled="currentIndex === paragraphs.length - 1"
          >
            End ⟹
          </button>
          <p v-if="currentIndex === paragraphs.length - 1" class="end-message">
            End of Book
          </p>
        </div>
      </section>

      <section v-else-if="isLoading" class="loading-container">
        <p class="loading-message">Loading book details…</p>
      </section>

      <section v-else class="error-container">
        <p class="error-message">{{ errorMessage }}</p>
      </section>
    </main>
  </div>
</template>

<script>
import Header from "./Header.vue";
import axios from "@/axiosConfig.js";

export default {
  name: "SimplerBookPieceByPiece",
  components: { Header },
  data() {
    return {
      book: { id: null, title: "", txt: "", price: 0 },
      bookContent: "",
      paragraphs: [],
      currentIndex: 0,
      errorMessage: "",
      isLoading: true,
      paid: false
    };
  },
  computed: {
    currentParagraph() {
      return this.paragraphs[this.currentIndex] || "";
    }
  },
  async created() {
    const bookId = this.$route.params.id;
    try {
      const response = await axios.get(`/api/stripe/book-details/${bookId}`);
      this.book = response.data;
    } catch (error) {
      this.errorMessage =
        error.response && error.response.status === 404
          ? "Book not found. Please contact resuvonia@gmail.com for assistance."
          : "Failed to load book details. Please try again.";
      this.isLoading = false;
      return;
    }

    this.isLoading = false;

    if (this.book && localStorage.getItem(`simplerBookContent_${this.book.id}`)) {
      this.bookContent = localStorage.getItem(`simplerBookContent_${this.book.id}`);
      this.paid = true;
    } else if (this.book.txt) {
      await this.fetchBookContent();
    }

    this.splitTextIntoParagraphs();

    const savedIndex = localStorage.getItem(`progress_${this.book.id}`);
    if (savedIndex !== null) {
      this.currentIndex = parseInt(savedIndex, 10);
    }

    this.$nextTick(() => {
      this.restoreScrollPosition();
    });

    window.addEventListener("keydown", this.handleKeyDown);
    window.addEventListener("pagehide", this.saveScrollPosition);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("pagehide", this.saveScrollPosition);
  },
  methods: {
    async fetchBookContent() {
      if (!this.book.txt) return;
      try {
        const response = await axios.get(this.book.txt, { responseType: "text" });
        this.bookContent = response.data;
        localStorage.setItem(`simplerBookContent_${this.book.id}`, response.data);
      } catch (error) {
        console.error("Failed to fetch book text:", error);
        this.bookContent = "(Failed to load book text.)";
      }
    },
    splitTextIntoParagraphs() {
      if (!this.bookContent) return;
      this.paragraphs = this.bookContent
        .split(/\n+/)
        .map((p) => p.trim())
        .filter((p) => p.length > 0);

      if (this.currentIndex >= this.paragraphs.length) {
        this.currentIndex = 0;
      }
    },
    nextParagraph() {
      if (this.currentIndex < this.paragraphs.length - 1) {
        this.currentIndex++;
        this.saveProgress();
      }
    },
    previousParagraph() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.saveProgress();
      }
    },
    jumpToBeginning() {
      this.currentIndex = 0;
      this.saveProgress();
    },
    jumpToEnd() {
      this.currentIndex = this.paragraphs.length - 1;
      this.saveProgress();
    },
    handleKeyDown(event) {
      if (event.code === "ArrowRight" || event.code === "Space") {
        event.preventDefault();
        this.nextParagraph();
      }
      else if (event.code === "ArrowLeft") {
        event.preventDefault();
        this.previousParagraph();
      }
    },
    saveProgress() {
      if (this.book.id) {
        localStorage.setItem(`progress_${this.book.id}`, this.currentIndex);
      }
    },
    saveScrollPosition() {
      if (this.$refs.container) {
        localStorage.setItem(
          `scrollPosition_Book_${this.book.id}`,
          this.$refs.container.scrollTop
        );
      }
    },
    restoreScrollPosition() {
      const savedPosition = localStorage.getItem(`scrollPosition_Book_${this.book.id}`);
      this.$nextTick(() => {
        if (this.$refs.container && savedPosition) {
          this.$refs.container.scrollTop = parseInt(savedPosition, 10);
        }
      });
    },
    goBackToChooseAction() {
      this.saveProgress();
      this.saveScrollPosition();
      const topicId = this.book.id || this.$route.params.id;
      const topicName = this.book.title || "Unknown Book";
      if (!topicId) {
        this.$router.push({ name: "books" });
        return;
      }
      this.$router.push({
        name: "simplerChooseAction",
        params: { id: topicId, topicType: "book", topicId: topicId, topicName }
      });
    }
  }
};
</script>

<style scoped>
.simpler-book-piece-by-piece-page {
  font-family: "Helvetica Neue", Arial, sans-serif;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.container {
  margin-top: 80px;
  height: calc(100vh - 80px);
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  background: linear-gradient(135deg, #ffffff, #f3f3f3);
  scroll-behavior: smooth;
  padding: 20px;
  padding-bottom: 120px;
  touch-action: pan-y;
  overscroll-behavior: contain;
}

.content {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  box-sizing: border-box;
}

.book-title {
  font-size: 1.8rem;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
  font-family: "Georgia", serif;
  font-weight: 600;
}

.paragraph-container {
  width: 100%;
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  margin-bottom: 20px;
}

.paragraph-text {
  font-size: 1.2rem;
  line-height: 1.6;
  text-align: justify;
  color: #444;
}

.instructions {
  margin-bottom: 20px;
  text-align: center;
  font-size: 1rem;
  color: #666;
}

.navigation {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.nav-button {
  background: linear-gradient(45deg, #7b4397, #dc2430);
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  transition: background 0.3s, transform 0.3s, box-shadow 0.3s;
}

.nav-button:hover {
  background: linear-gradient(45deg, #ac4dac, #ff4747);
  transform: translateY(-2px);
  box-shadow: 0 8px 18px rgba(0,0,0,0.2);
}

.nav-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.end-message {
  font-size: 1rem;
  color: #555;
  margin-top: 10px;
}

@media (max-width: 600px) {
  .container {
    padding: 10px;
    padding-bottom: 100px;
  }
  .content {
    max-width: 100%;
    margin: 0;
  }
  .book-title {
    font-size: 1.5rem;
  }
  .paragraph-text {
    font-size: 1rem;
  }
  .nav-button {
    padding: 8px 16px;
    font-size: 14px;
  }
  .instructions {
    font-size: 0.9rem;
  }
}
</style>

<style>
/* Global Scrollbar Styles */
::-webkit-scrollbar {
  width: 10px;
  background-color: #f0f0f0;
}
::-webkit-scrollbar-track {
  background-color: #e6e6e6;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
  border: 2px solid #e6e6e6;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
html {
  scrollbar-width: thin;
  scrollbar-color: #888 #e6e6e6;
}
</style>
