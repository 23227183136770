<!-- SimplerChooseActionPage.vue -->
<template>
  <div class="container">
    <Header
      :showBackButton="true"
      backText="Back to Choose Action"
      @back="goBack"
    />

    <main class="content" role="main">
      <h1>Choose an Action for Simpler Book: {{ topicName }}</h1>

      <!-- Option to read the simpler version in whole -->
      <div
        class="action-button"
        role="button"
        tabindex="0"
        aria-label="Read Simpler Version in Whole"
        @click="readSimplerInWhole"
        @keydown.enter="readSimplerInWhole"
      >
        Read Simpler Version in Whole
      </div>

      <!-- Option to read the simpler version piece by piece -->
      <div
        class="action-button"
        role="button"
        tabindex="0"
        aria-label="Read Simpler Version Piece by Piece"
        @click="readSimplerPieceByPiece"
        @keydown.enter="readSimplerPieceByPiece"
      >
        Read Simpler Version Piece by Piece
      </div>
    </main>
  </div>
</template>

<script>
import Header from "./Header.vue";

export default {
  name: "SimplerChooseActionPage",
  components: { Header },
  props: {
    topicType: {
      type: String,
      required: true
    },
    topicId: {
      type: Number,
      required: true
    },
    topicName: {
      type: String,
      required: true
    },
    id: {
      type: [Number, String],
      required: true
    }
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "chooseAction",
        params: { id: this.topicId, topicType: "book", topicId: this.topicId, topicName: this.topicName }
      });
    },
    readSimplerInWhole() {
      this.$router.push({
        name: "simplerBookDetails",
        params: { id: this.topicId }
      });
    },
    readSimplerPieceByPiece() {
      this.$router.push({
        name: "simplerBookPieceByPiece",
        params: { id: this.topicId }
      });
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: "Helvetica Neue", Arial, sans-serif;
  background: #fafafa;
  margin: 0;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  margin-top: 60px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  flex: 1;
  background: linear-gradient(135deg, rgba(249, 249, 249, 0.8), rgba(243, 243, 243, 0.8));
  backdrop-filter: blur(4px);
  border-radius: 10px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

.content h1 {
  font-size: 2rem;
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  font-weight: 600;
}

.action-button {
  background: linear-gradient(45deg, #7b4397, #dc2430);
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 18px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  max-width: 260px;
  margin: 15px 0;
  font-size: 1rem;
  font-weight: 500;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  outline: none;
}

.action-button:hover,
.action-button:focus {
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  background: linear-gradient(45deg, #ac4dac, #ff4747);
}
</style>
