<!-- BooksPage.vue -->
<template>
  <div class="books-page">
    <!-- Header without back button since this is the landing page -->
    <Header />

    <!-- Scrollable area below the header -->
    <main class="container" ref="container" role="main">
      <!-- Page Title & Search -->
      <section class="search-section">
        <h1 class="page-title">Search For Books</h1>
        <input
          type="text"
          v-model="searchQuery"
          placeholder="Search for a book"
          @input="saveSearchQuery"
          aria-label="Search for a book"
        />
      </section>

      <!-- Genre Filter Buttons -->
      <section class="genres">
        <div class="genres-container">
          <button
            v-for="genre in genres"
            :key="genre"
            @click="filterByGenre(genre)"
            :class="['genre-button', { 'active-genre': selectedGenre === genre }]"
            :aria-label="`Filter by ${genre} genre`"
          >
            {{ genre }}
          </button>
        </div>
      </section>

      <!-- Books Grid -->
      <section class="books-grid">
        <div
          v-for="book in filteredBooks"
          :key="book.id"
          class="book-item"
          role="button"
          tabindex="0"
          @click="goToChooseAction(book.id, book.title)"
          @keydown.enter="goToChooseAction(book.id, book.title)"
          :aria-label="`View details for ${book.title}`"
        >
          <img
            :src="book.image"
            :alt="`Cover of ${book.title}`"
            class="book-image"
          />
          <p class="book-title">{{ book.title }}</p>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import Header from "./Header.vue";
import books from "../../data/books.js";

export default {
  name: "BooksPage",
  components: { Header },
  data() {
    return {
      searchQuery: "",
      selectedGenre: "All",
      genres: [
        "All",
        "Classics",
        "Science Fiction & Fantasy",
        "Mystery & Thriller",
        "Philosophy",
        "Drama & Plays",
        "Historical",
        "Non-Fiction",
        "Adventure",
        "Romance",
        "Poetry",
        "Children's Literature",
        "Horror & Gothic",
        "Social & Political",
        "Science & Mathematics",
        "Mythology & Legends",
        "Biography & Autobiography",
        "Satire",
        "Religion & Spirituality",
      ],
      booksList: books,
    };
  },
  computed: {
    filteredBooks() {
      const query = this.searchQuery.toLowerCase();
      const uniqueTitles = new Set();
      return this.booksList.filter((book) => {
        const matchesQuery = book.title.toLowerCase().includes(query);
        const bookGenres = book.genre ? book.genre.split(", ") : [];
        const matchesGenre =
          this.selectedGenre === "All" || bookGenres.includes(this.selectedGenre);
        if (matchesQuery && matchesGenre && !uniqueTitles.has(book.title)) {
          uniqueTitles.add(book.title);
          return true;
        }
        return false;
      });
    },
  },
  methods: {
    saveSearchQuery() {
      localStorage.setItem("bookSearchQuery", this.searchQuery);
    },
    getSearchQuery() {
      return localStorage.getItem("bookSearchQuery") || "";
    },
    saveScrollPosition() {
      if (this.$refs.container) {
        localStorage.setItem("scrollPosition_BooksPage", this.$refs.container.scrollTop);
      }
    },
    restoreScrollPosition() {
      const pos = localStorage.getItem("scrollPosition_BooksPage");
      this.$nextTick(() => {
        if (this.$refs.container && pos) {
          this.$refs.container.scrollTop = parseInt(pos, 10);
        }
      });
    },
    goToChooseAction(bookId, bookTitle) {
      this.$router.push({
        name: "chooseAction",
        params: { id: bookId, topicType: "book", topicId: bookId, topicName: bookTitle },
      });
    },
    filterByGenre(genre) {
      this.selectedGenre = genre;
      localStorage.setItem("selectedGenre_BooksPage", genre);
    },
  },
  mounted() {
    this.searchQuery = this.getSearchQuery();
    const savedGenre = localStorage.getItem("selectedGenre_BooksPage");
    if (savedGenre) {
      this.selectedGenre = savedGenre;
    }
    this.restoreScrollPosition();
    if (this.$refs.container) {
      this.$refs.container.addEventListener("scroll", this.saveScrollPosition);
    }
    window.addEventListener("pagehide", this.saveScrollPosition);
  },
  beforeRouteLeave(to, from, next) {
    this.saveScrollPosition();
    this.saveSearchQuery();
    localStorage.setItem("selectedGenre_BooksPage", this.selectedGenre);
    next();
  },
  beforeDestroy() {
    if (this.$refs.container) {
      this.$refs.container.removeEventListener("scroll", this.saveScrollPosition);
    }
    window.removeEventListener("pagehide", this.saveScrollPosition);
  },
};
</script>

<style scoped>
.books-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.container {
  margin-top: 80px;
  min-height: calc(100vh - 80px);
  overflow-y: auto;
  scroll-behavior: smooth;
  box-sizing: border-box;
  background-color: #f9f9f9;
  font-family: "Helvetica Neue", Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-bottom: 120px;
}

.search-section {
  width: 100%;
  max-width: 900px;
  margin-bottom: 20px;
  text-align: center;
}
.page-title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
  font-weight: 600;
  font-family: "Georgia", serif;
}
.search-section input[type="text"] {
  width: 100%;
  max-width: 600px;
  padding: 10px;
  margin: 0 auto;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  font-family: inherit;
  transition: border-color 0.3s;
}
.search-section input[type="text"]:focus {
  outline: none;
  border-color: #333;
}

.genres {
  width: 100%;
  max-width: 900px;
}
.genres-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}
.genre-button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background: linear-gradient(135deg, #444444, #2c2c2c);
  color: #ffffff;
  cursor: pointer;
  font-size: 0.95rem;
  transition: transform 0.3s, box-shadow 0.3s;
}
.genre-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}
.active-genre {
  background: linear-gradient(135deg, #f39c12, #e74c3c) !important;
  transform: scale(1.1) !important;
  box-shadow: 0 8px 20px rgba(255, 130, 0, 0.4) !important;
  color: #fff !important;
  font-weight: 600 !important;
}

.books-grid {
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}
.book-item {
  text-align: center;
  cursor: pointer;
  width: 150px;
  outline: none;
  transition: transform 0.3s, box-shadow 0.3s;
}
.book-item:hover,
.book-item:focus {
  transform: translateY(-3px);
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.15);
}
.book-image {
  width: 150px;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 8px;
}
.book-title {
  font-size: 1rem;
  color: #333;
}
</style>

<style>
::-webkit-scrollbar {
  width: 10px;
  background-color: #f0f0f0;
}
::-webkit-scrollbar-track {
  background-color: #e6e6e6;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
  border: 2px solid #e6e6e6;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
html {
  scrollbar-width: thin;
  scrollbar-color: #888 #e6e6e6;
}
</style>
